<template>
  <PageWithLayout>
    <div class="group_box">
      <!-- Input -->
      <BoxView title="Input">
        <Input
          placeholder="default input"
          :value.sync="defaultInput"/>
        <Input
          placeholder="focus input"
          :isFocusOnMount="true"
          :value.sync="focusInput"/>
        <Input
          placeholder="value input"
          :value.sync="valueInput"/>
        <Input
          placeholder="password input"
          type="password"
          valueType="password"
          :value.sync="passwordInput"/>
        <Input
          placeholder="count input"
          :maxLength="50"
          :isCount="true"
          :value.sync="countInput"/>
        <Input
          placeholder="disabled input"
          :value.sync="disabledInput"
          :isDisabled="true"/>
        <Input
          placeholder="disabled value input"
          :value.sync="disabledValueInput"
          :isDisabled="true"/>
        <Input
          placeholder="error input"
          :value.sync="errorInput"
          :isError="true"
          errorDesc="에러문구입니다"/>
        <Input
          placeholder="large input"
          size="large"
          :value.sync="largeInput"/>
      </BoxView>
      <!-- Textarea -->
      <BoxView title="Textarea">
        <Textarea
          placeholder="default textarea / default show 2 line"
          :showLine="2"
          :value.sync="defaultTextarea"/>
        <Textarea
          placeholder="count textarea / default show 3 line"
          :showLine="3"
          :value.sync="countTextarea"
          :isCount="true"/>
        <Textarea
          placeholder="value count textarea / default show 4 line"
          :value.sync="valueCountTextarea"
          :showLine="4"
          :isCount="true"/>
        <Textarea
          placeholder="disabled count textarea"
          :value.sync="disabledCountTextarea"
          :max="100"
          :isCount="true"
          :isDisabled="true"/>
        <Textarea
          placeholder="error textarea"
          :value.sync="errorTextarea"
          :max="100"
          :isCount="true"
          :isError="true"
          errorDesc="에러문구입니다"/>
      </BoxView>
      <!-- Selectbox -->
      <BoxView title="Selectbox">
        <Selectbox
          :dataList="selectboxDataList"
          :value.sync="selectboxvalue"/>
        <Selectbox
          placeholder="multi select"
          :canMultiple="true"
          :dataList="selectboxDataList"
          :value.sync="selectboxMultipleValue"/>
        <Selectbox
          placeholder="info select"
          :dataList="selectboxInfoDataList"
          :value.sync="selectboxInfoValue"/>
        <Selectbox
          placeholder="empty"
          :dataList="selectboxEmptyDataList"
          :value.sync="selectboxEmptyValue"/>
        <Selectbox
          placeholder="disabled"
          :isDisabled="true"
          :dataList="selectboxEmptyDataList"
          :value.sync="selectboxEmptyValue"/>
        <Selectbox
          placeholder="disabled value"
          :isDisabled="true"
          :dataList="selectboxDataList"
          :value.sync="selectboxDisabledValue"/>
      </BoxView>
      <!-- Switch -->
      <BoxView title="Switch">
        <div class="group_switch">
          <SwitchCheck :value.sync="valueSwitch" text="ON" />
          <SwitchCheck :value.sync="isSwitch" text="OFF" />
        </div>
        <div class="group_switch">
          <SwitchCheck :value.sync="isDisabledActiveSwitch"
            :isDisabled="true" text="disabled ON" />
          <SwitchCheck :value.sync="isDisabledSwitch"
            :isDisabled="true" text="disabled OFF" />
        </div>
      </BoxView>
      <!-- Radio -->
      <BoxView title="Radio">
        <Radio
          :dataList="radioDataList"
          :value.sync="radiovalue"/>
        <Radio
          :dataList="radioDisabledDataList"
          :value.sync="radioDisabledvalue"
          :isDisabled="true" />
      </BoxView>
      <!-- Checkbox -->
      <BoxView title="Checkbox">
        <Checkbox
          :dataList="checkboxDataList"
          :value.sync="value"/>
        <Checkbox
          :dataList="checkboxDisabledDataList"
          :value.sync="checkedDisabledNames"
          :isDisabled="true" />
        <CheckboxOnlyOne
          id="checkboxOnlyOne"
          text="checkboxOnlyOne"
          :value.sync="CheckboxOnlyOne"/>
      </BoxView>
      <!-- DatePicker -->
      <BoxView title="DatePicker"  :grid="100">
        <strong class="sub_title">DatePicker</strong>
        <DatePicker
          :value.sync="dateValue"
          maxWidth="50%"/>
        <strong class="sub_title">minDate : {{ new Date() | convertDateFormat('YYYY-MM-DD') }}</strong>
        <DatePicker
          :value.sync="minDateValue"
          :minDate="new Date() | convertDateFormat('YYYY-MM-DD')"
          maxWidth="50%"/>
        <strong class="sub_title">maxDate : {{ new Date() | convertDateFormat('YYYY-MM-DD') }}</strong>
        <DatePicker
          :value.sync="maxDateValue"
          :maxDate="new Date() | convertDateFormat('YYYY-MM-DD')"
          maxWidth="50%"/>
        <strong class="sub_title">DatePicker - disabled</strong>
        <DatePicker
          value="2022-12-31"
          :isDisabled="true"
          maxWidth="50%"/>
        <strong class="sub_title">DateRange</strong>
        <DateRange
          :value.sync="dateRangeValue"
          maxWidth="50%"/>
        <strong class="sub_title">DateRange - disabled</strong>
        <DateRange
          :value="['2022-01-01','2022-12-31']"
          :isDisabled="true"
          maxWidth="50%"/>
        <strong class="sub_title">DateRangeQuick</strong>
        <Checkbox
          :dataList="quickTypeDataSelect"
          :value.sync="quickTypeDataSelectValue"
          @update:value="value => setDateRangeQuickValue(value)"/>
        <DateRangeQuick
          :value.sync="dateRangeQuickValue"
          :quickTypeData="quickTypeData"
          maxWidth="50%"/>
        <strong class="sub_title">DateTimePicker</strong>
        <DateTimePicker
          :value.sync="dateTimeValue"
          maxWidth="50%"/>
        <strong class="sub_title">DateTimePicker - disabled</strong>
        <DateTimePicker
          value=""
          :isDisabled="true"
          maxWidth="50%"/>
        <strong class="sub_title">TimePicker</strong>
        <TimePicker
          :value.sync="timeValue"
          maxWidth="50%"/>
        <strong class="sub_title">TimePicker - disabled</strong>
        <TimePicker
          value=""
          :isDisabled="true"
          maxWidth="50%"/>
      </BoxView>
      <!-- Editor -->
      <BoxView title="Editor library - tiptap" :grid="100">
        <strong class="sub_title">메뉴 선택 (커스텀 추가 하지 않으면 전체 메뉴 노출)</strong>
        <Checkbox
          :dataList="editorMenuList"
          :value.sync="editorMenuValue"/>
        <EditorTiptap
          :value.sync="editorTiptapValue"
          :menuDataList="editorMenuValue"
          :maxLength="10000"/>
      </BoxView>
      <!-- FileUploader -->
      <BoxView title="FileUploader" :grid="100">
        <FileUpload
          :dataList.sync="fileUploadedDataList"
          :canMultiple="false"
          :acceptList="['png','jpg','jpeg']"
          sizeType="MIN"/>
        <FileUpload
          :dataList.sync="fileDataList"
          :canMultiple="false"
          :acceptList="['png','jpg','jpeg']"
          sizeType="MIN"/>
      </BoxView>
      <!-- ImgViewer -->
      <BoxView title="ImgViewer" :grid="100">
        ImgViewer
      </BoxView>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import EditorTiptap from '@lemontree-ai/lemontree-admin-common-front/components/common/editor/EditorTiptap';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import DatePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DatePicker';
import DateRange from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateRange';
import DateRangeQuick from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateRangeQuick';
import DateTimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateTimePicker';
import TimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/timePicker/TimePicker';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Checkbox from '@lemontree-ai/lemontree-admin-common-front/components/common/checkbox/Checkbox';
import CheckboxOnlyOne from '@lemontree-ai/lemontree-admin-common-front/components/common/checkbox/CheckboxOnlyOne';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';

export default {
  name:'Forms',
  components:{
    PageWithLayout,
    BoxView,
    Input,
    Textarea,
    EditorTiptap,
    Selectbox,
    DatePicker,
    DateRange,
    DateRangeQuick,
    DateTimePicker,
    TimePicker,
    Radio,
    Checkbox,
    CheckboxOnlyOne,
    SwitchCheck,
    FileUpload
  },
  data(){
    return{
      //input
      defaultInput:'',
      focusInput:'',
      valueInput:'value input',
      passwordInput:'password',
      countInput:'count input',
      disabledInput:'',
      disabledValueInput:'disabled value input',
      errorInput:'error input',
      largeInput:'',
      //textarea
      defaultTextarea:'',
      countTextarea:'',
      valueCountTextarea:'value count textarea',
      disabledCountTextarea:'disabled value count textarea',
      errorTextarea:'error textarea',
      // editor
      editorMenuList:[
        { id:'bold', text:'bold' },
        { id:'italic', text:'italic' },
        { id:'strike', text:'strike' },
        { id:'underline', text:'underline' },
        { id:'horizontalRule', text:'horizontalRule' },
        { id:'highlight', text:'highlight' },
        { id:'link', text:'link' },
        { id:'image', text:'image' },
        { id:'youtube', text:'youtube' },
        { id:'title', text:'content title' },
        { id:'heading1', text:'heading1' },
        { id:'heading2', text:'heading2' },
        { id:'heading3', text:'heading3' },
        { id:'bulletList', text:'bulletList' },
        { id:'orderedList', text:'orderedList' },
        { id:'blockquote', text:'blockquote' },
        { id:'clear_format', text:'clear_format', isDisabled: true },
        { id:'undo', text:'undo', isDisabled: true },
        { id:'redo', text:'redo', isDisabled: true },
      ],
      editorMenuValue:['bold','italic','strike','underline','horizontalRule','highlight','link','image','youtube','title','heading1','heading2','heading3','bulletList','orderedList','blockquote','clear_format','undo','redo'],
      editorTiptapValue:`<h1>제목1</h1><h2>제목2</h2><h3>제목3</h3><p>안녕하세요? 반갑습니다</p><p><em>우측상단에 Mode도 변경해보세요!</em></p><p>에디터 <span data-name="txt_highlight">하이라이트</span> 기능을 <u>테스트</u>합니다 <s>취소</s></p><p><a target="_blank" rel="noopener noreferrer nofollow" href="https://content.dev.lemontree.ai/world-map">링크</a>도 추가 / 이미지 업로드는 아직 대기중</p><ol><li><p>첫번째</p></li><li><p>두번째</p><ol><li><p>순서있는 리스트 댑스</p><ol><li><p>타입변경 가능</p></li></ol></li></ol></li></ol><p></p><span data-name="title_content">콘텐츠(Lesson에 들어갈 예정) 제목</span><blockquote><p>인용문구</p></blockquote><ul><li><p>순서없는 리스트</p><ul><li><p>스타일은 언제든 수정할 수 있으니 필요하시면 문의주세요</p><ul><li><p>리스트 댑스 타입도 수정가능합니다</p></li></ul></li></ul></li></ul>`,
      // selectbox
      selectboxDataList:[
        { id:'lemon', text:'레몬' },
        { id:'apple', text:'사과' },
        { id:'banana', text:'바나나' },
        { id:'grape', text:'포도' },
        { id:'melon', text:'멜론' },
        { id:'orange', text:'오렌지' },
        { id:'strawberry', text:'딸기' },
        { id:'chestnut', text:'밤', isDisabled: true },
        { id:'citron', text:'유자' },
        { id:'apricot', text:'살구' },
      ],
      selectboxvalue:'',
      selectboxMultipleValue:[],
      selectboxInfoDataList:[
        { id:'lemon', text:'레몬', info:'상세설명을 추가해봅니다' },
        { id:'apple', text:'사과', info:'상세설명을 추가해봅니다' },
        { id:'banana', text:'바나나', info:'상세설명을 추가해봅니다' },
        { id:'grape', text:'포도', info:'상세설명을 추가해봅니다' },
        { id:'melon', text:'멜론', info:'상세설명을 추가해봅니다' },
        { id:'orange', text:'오렌지', info:'상세설명을 추가해봅니다 상세설명을 추가해봅니다 상세설명을 추가해봅니다 상세설명을 추가해봅니다' },
        { id:'strawberry', text:'딸기', info:'상세설명을 추가해봅니다' },
        { id:'chestnut', text:'밤', info:'상세설명을 추가해봅니다', isDisabled: true },
        { id:'citron', text:'유자', info:'상세설명을 추가해봅니다' },
        { id:'apricot', text:'살구', info:'상세설명을 추가해봅니다' },
      ],
      selectboxInfoValue:'',
      selectboxEmptyDataList: [],
      selectboxEmptyValue:'',
      selectboxDisabledValue:'lemon',
      // datePicker
      dateValue: '',
      minDateValue:'',
      maxDateValue:'',
      dateRangeValue: [],
      quickTypeDataSelect:[
        { id: 'TODAY', text: '오늘' },
        { id: 'ONE_WEEK', text: '1주일' },
        { id: 'ONE_MONTH', text: '1개월' },
        { id: 'THREE_MONTH', text: '3개월' },
        { id: 'SIX_MONTH', text: '6개월' },
        { id: 'ONE_YEAR', text: '1년' },
        { id: 'THIS_WEEK', text: '이번주' },
        { id: 'THIS_MONTH', text: '이번달' },
        { id: 'LAST_MONTH', text: '지난달' },
      ],
      quickTypeDataSelectValue:[ 'TODAY','ONE_WEEK', 'ONE_MONTH', 'THREE_MONTH', 'SIX_MONTH', 'ONE_YEAR', 'THIS_WEEK', 'THIS_MONTH', 'LAST_MONTH' ],
      quickTypeData:[
        { quickType : 'TODAY' },
        { quickType : 'ONE_WEEK' },
        { quickType : 'ONE_MONTH' },
        { quickType : 'THREE_MONTH' },
        { quickType : 'SIX_MONTH' },
        { quickType : 'ONE_YEAR' },
        { quickType : 'THIS_WEEK' },
        { quickType : 'THIS_MONTH' },
        { quickType : 'LAST_MONTH' },
      ],
      dateRangeQuickValue: [],
      dateTimeValue:'',
      // timeValue:{
      //   H:'18',
      //   M:'42',
      //   S:'20',
      // },
      timeValue:'18:42:20',
      //radio
      radioDataList:[
        { id:'radio', text:'radio' },
        { id:'radioSelected', text:'radioSelected' }
      ],
      radiovalue:'radioSelected',
      radioDisabledDataList:[
        { id:'radioDisabled', text:'radioDisabled' },
        { id:'radioDisabledSelected', text:'radioDisabledSelected' }
      ],
      radioDisabledvalue:'radioDisabledSelected',
      //checkbox
      checkboxDataList:[
        { id:'checkbox', text:'checkbox' },
        { id:'checkboxChecked', text:'checkboxChecked' }
      ],
      value:['checkboxChecked'],
      checkboxDisabledDataList:[
        { id:'checkboxDisabled', text:'checkboxDisabled' },
        { id:'checkboxDisabledChecked', text:'checkboxDisabledChecked' }
      ],
      checkedDisabledNames:['checkboxDisabledChecked'],
      CheckboxOnlyOne:false,
      //switch
      isSwitch:false,
      valueSwitch:true,
      isDisabledSwitch:false,
      isDisabledActiveSwitch:true,
      // file
      fileUploadedDataList : [
        {
          src:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png',
          isUploaded: true,
        }
      ],
      fileDataList: [
        // {
        //   src:'https://lemontree-content-data-dev.s3.ap-northeast-2.amazonaws.com/images/c7/8e/c78e3bf6-ffa4-44e0-acbb-d84d2bfa4d1e'
        // }
      ],
    }
  },
  methods:{
    setDateRangeQuickValue(value){
      this.quickTypeData = value.map(item => {
        let rObj = { quickType: item };
        return rObj
      })
    }
  }
}
</script>

<style scoped>
/* input */
.box_view::v-deep .tf_comm,
.box_view::v-deep .selectbox_comm,
.box_view .body_view > .datepicker_comm,
.box_view .body_view > .timepicker_comm{display:block}

.box_view::v-deep .tf_comm + .tf_comm,
.box_view::v-deep .textarea_comm + .textarea_comm,
.box_view::v-deep .selectbox_comm + .selectbox_comm,
.box_view::v-deep .group_radio + .group_radio,
.box_view::v-deep .group_check + .group_check,
.box_view::v-deep .group_check + .check_comm,
.box_view::v-deep .group_switch + .group_switch,
.box_view::v-deep .datepicker_comm + .datepicker_comm,
.box_view::v-deep .datepicker_comm + .datepicker_quick,
.box_view::v-deep .datepicker_quick + .datatime_comm,
.box_view::v-deep .datatime_comm + .datatime_comm,
.box_view::v-deep .timepicker_comm + .timepicker_comm,
.box_view::v-deep .datatime_comm + .timepicker_comm,
.box_view::v-deep .file_upload + .file_upload{margin-top:20px}

.box_view .sub_title{display:block;padding:30px 0 10px;color:#222}
.box_view .sub_title:first-child{padding-top:0}

@media (prefers-color-scheme: dark) {
  .box_view .sub_title{color:#ccc}
}
</style>